:root {
  --mainBackground: rgb(75, 87, 124);
}

body {
  font-size: 16px;
}

.ant-layout-sider {
  background-color: var(--mainBackground) !important;
}

/* Cards link in sidebar */
.ant-menu-item-selected {
  background-color: var(--mainBackground) !important;
}

.MuiLink-underlineHover:hover {
  text-decoration: none !important;
}
